.ticker-v1_0 {
  @import "../shared/_defaults";
  @include origin-flag-simple;

  .fullscreen-wrapper {
    background: $chromakey-green;
  }

  .display-wrapper {
    top: auto;
    bottom: 0;
    font-size: 10vmin;
    height: 1em;
    background-color: lighten($prod-dark-blue, 5%);
  }

  .display-header {
    font-size: .25em;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    padding: .15em 5vw;
    z-index: 1;
  }

  .networks {
    position: absolute;
    top: .4em;
    right: calc(5vw + 12em);
  }

  .network-icon {
    @include network-icon;
  }

  .posts {
    top: .5em;
    left: 5%;
    width: 90%;
    height: .45em;
  }

  .post {
    font-size: .3em;
    line-height: 1.5;
    position: absolute;
    width: 100%;
    height: 100%;
    color: $white;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    br {
      content: " ";
      display: inline;
      padding: .2em;
    }
  }

  .text-module {
    float: right;
    width: calc(100% - 1.5em);
    height: 100%;
    overflow: hidden;
  }

  .message {
    position: relative;
    float: right;
    width: auto;
    height: 100%;
    overflow: hidden;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
  }

  .post-scroll {
    white-space: nowrap;
    position: absolute;
  }

  .post-title,
  .post-text {
    display: inline-block;
    white-space: nowrap;
  }

  .post-title {
    font-weight: bold;
  }

  .meta-author {
    @include ellipsis;
    position: relative;
    float: left;
    width: auto;
    height: 100%;
    padding-left: 2em;
    padding-right: 1em;
  }

  .no-avatar {
    .meta-author {
      padding-left: 0;
    }
    .avatar {
      display: none;
    }
  }

  .avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;
    width: 1.5em;
    height: 1.5em;
    border-radius: 100%;
    box-shadow: 0 0 4px rgba($black, .5);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .user-img {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  .twitter .handle,
  .tiktok .handle {
    font-size: .8em;
    margin-left: .25em;
  }

  .reddit .subreddit + .handle {
    font-size: .8em;
    margin-left: .25em;
  }

  .origin-flag {
    top: .25em;
    left: 0;
  }
}
